import React, { useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Formik } from 'formik';
import { useViewlogies } from 'context';
import { getGuestCookie, setGuestCookie, insertGuest } from 'services/guest';
import './guestForm.scss';
import { FormattedMessage } from 'react-intl';
import { FadeLoader } from 'react-spinners';
import SubmissionForm from './SubmissionForm';
import Header from './Header';
import isEventStarted from './isEventStarted';

const GuestForm = ({ intl }) => {
    const {
        event: { id, organizationId, firstName, lastName, color },
        fetchGuestList,
        streams
    } = useViewlogies();

    const eventStarted = isEventStarted(streams);

    const [message, setMessage] = useState('');
    const [isRecaptchaError, setIsRecaptchaError] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(getGuestCookie(id) === 'true');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const recaptchaRef = useRef();

    const loaderStyles = `
        margin-left: 20px;
    `;

    return (
        <section className="guest-form-container">
            <Header
                intl={intl}
                lastName={lastName}
                eventStarted={eventStarted}
                color={color}
                isFormSubmitted={isFormSubmitted}
                isSubmitting={isSubmitting}
            />
            {isFormSubmitted || isSubmitting ? (
                <div className="guest-form-description">
                    {isSubmitting ? (
                        <div className="submission-loader-container">
                            <FadeLoader
                                color={'#646464'}
                                margin={-5}
                                height={10}
                                width={3}
                                radius={1}
                                css={loaderStyles}
                            />
                        </div>
                    ) : eventStarted ? (
                        <FormattedMessage
                            id="features.Viewlogy.GuestSection.Form.nameAdded"
                            defaultMessage="Your name has been added to the Guest List below..."
                        />
                    ) : (
                        <FormattedMessage
                            id="features.Viewlogy.GuestSection.Form.rsvpNameAdded"
                            defaultMessage={`The livestream for ${firstName} will start below.`}
                        />
                    )}
                </div>
            ) : (
                <>
                    <Formik
                        initialValues={{ name: '', email: '' }}
                        onSubmit={async values => {
                            setMessage('');
                            const token = await recaptchaRef.current.getValue();
                            if (!token) {
                                setIsRecaptchaError(true);
                            } else {
                                setIsRecaptchaError(false);
                                setMessage('');
                                setIsSubmitting(true);
                                const { status } = await insertGuest(id, organizationId, values, token);
                                if (status === 201) {
                                    setGuestCookie(id);
                                    await fetchGuestList();
                                    setIsSubmitting(false);
                                    setIsFormSubmitted(true);
                                } else {
                                    console.error('There was an error', status);
                                    setMessage(
                                        intl.formatMessage({
                                            id: 'features.Viewlogy.GuestSection.Form.error',
                                            defaultMessage: 'There was an error, please try again.'
                                        })
                                    );
                                }
                            }
                        }}
                    >
                        {({ values, touched, isSubmitting }) => (
                            <SubmissionForm
                                values={values}
                                touched={touched}
                                isSubmitting={isSubmitting}
                                recaptchaRef={recaptchaRef}
                                setMessage={setMessage}
                                isRecaptchaError={isRecaptchaError}
                                message={message}
                                intl={intl}
                                color={color}
                            />
                        )}
                    </Formik>
                </>
            )}
        </section>
    );
};

export default injectIntl(GuestForm);
