const { FormattedMessage } = require('react-intl');

const Disclaimers = () => {
    return (
        <>
            <p className="guest-form-disclaimer">
                <FormattedMessage
                    id="features.Viewlogy.GuestSection.Form.disclaimer1"
                    defaultMessage="By signing the guest list, you agree to our <a>Terms of Service</a> and <b>Privacy Policy</b>"
                    values={{
                        a: chunks => <a href="https://www.viewlogies.com/terms">{chunks}</a>,
                        b: chunks => <a href="https://www.viewlogies.com/privacy">{chunks}</a>
                    }}
                />
                {/*{`By signing the guest list, you agree to our `}*/}
                {/*<a href="https://www.viewlogies.com/terms">{`Terms of Service`}</a>*/}
                {/*{` and `}*/}
                {/*<a href="https://www.viewlogies.com/privacy">{`Privacy Policy`}</a>*/}
            </p>
            <p className="guest-form-disclaimer">
                <FormattedMessage
                    id="features.Viewlogy.GuestSection.Form.protected"
                    defaultMessage="This site is protected by reCAPTCHA and the <a>Google Privacy Policy</a> and <b>Terms of Service</b> apply."
                    values={{
                        a: chunks => <a href="https://policies.google.com/privacy">{chunks}</a>,
                        b: chunks => <a href="https://policies.google.com/terms">{chunks}</a>
                    }}
                />
                {/*{`This site is protected by reCAPTCHA and the `}*/}
                {/*<a href="https://policies.google.com/privacy">{`Google Privacy Policy`}</a>*/}
                {/*{` and `}*/}
                {/*<a href="https://policies.google.com/terms">{`Terms of Service`}</a>*/}
                {/*{` apply.`}*/}
            </p>
        </>
    );
};

export default Disclaimers;
