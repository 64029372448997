import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactCountdown from 'react-countdown';
import './countdown.scss';
import CallToAction from './CallToAction';

const zeroPad = num => (num > 99 ? `${num}` : ('0' + num).slice(-2));

const Countdown = ({ intl, date, hide, hidden = false, obituaryUrl }) => {
    return (
        <>
            <div className={`countdown-container${hidden ? ' hidden' : ''}`}>
                <div className="countdown">
                    <CallToAction />
                    <ReactCountdown
                        date={date}
                        renderer={({ days, hours, minutes }) => (
                            <span>
                                <h2>
                                    <FormattedMessage
                                        id="features.Viewlogy.VideoPlayer.Countdown.willStartIn"
                                        defaultMessage="This Private Broadcast™ will start in"
                                    />
                                </h2>
                                <div className="countdown-dhm">
                                    <div className="countdown-time-unit">
                                        <h3>
                                            <FormattedMessage
                                                id="features.Viewlogy.VideoPlayer.days"
                                                defaultMessage="days"
                                            />
                                        </h3>
                                        <p className={'countdown-number'}>{zeroPad(days)}</p>
                                    </div>
                                    <div className="countdown-time-unit">
                                        <h3>
                                            <FormattedMessage
                                                id="features.Viewlogy.VideoPlayer.hours"
                                                defaultMessage="hours"
                                            />
                                        </h3>
                                        <p className={'countdown-number'}>{zeroPad(hours)}</p>
                                    </div>
                                    <div className="countdown-time-unit">
                                        <h3>
                                            <FormattedMessage
                                                id="features.Viewlogy.VideoPlayer.minutes"
                                                defaultMessage="minutes"
                                            />
                                        </h3>
                                        <p className={'countdown-number'}>{zeroPad(minutes)}</p>
                                    </div>
                                </div>
                            </span>
                        )}
                    />
                </div>
                <div className="live-caption">
                    <div className="circle" />
                    <div>{'Live'}</div>
                </div>
            </div>
        </>
    );
};

export default injectIntl(Countdown);
