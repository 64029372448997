import React, { useState, useRef } from 'react';
import Button from 'components/Button';
import 'index.scss';
import './slideshowUpload.scss';

const allowedFileTypesRE = /(\.mp4|\.mov|\.wmv|\.avi|\.flv)$/i;

const FileInput = ({
    handleSubmit,
    videoExists,
    isUploading,
    setIsUploading,
    uploadPercent,
    setUploadPercent,
    errorMessage,
    setErrorMessage
}) => {
    const fileInput = useRef(null);

    const [filename, setFilename] = useState('');

    const _handleSubmit = async e => {
        e.preventDefault();
        setIsUploading(true);
        await handleSubmit(fileInput.current.files[0]);
    };

    const pickFileMessage = videoExists ? 'Choose replacement file' : 'Choose a file to upload';
    const isUploadComplete = uploadPercent === 100;
    const uploadMessage = isUploadComplete ? 'Upload complete' : `Uploading ${uploadPercent.toFixed(2)}%`;

    return (
        <form onSubmit={_handleSubmit} className="action-input slideshow-upload-input">
            <label htmlFor="file-picker" className="viewlogies-button">
                {pickFileMessage}
            </label>
            {filename && <p>{`${isUploadComplete ? 'Uploaded' : 'Selected'} file: ${filename}`}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <input
                type="file"
                id="file-picker"
                ref={fileInput}
                onChange={() => {
                    setUploadPercent(0.0);
                    setIsUploading(false);
                    if (fileInput.current.files[0]) {
                        const [{ name }] = fileInput.current.files;
                        if (!allowedFileTypesRE.exec(name)) {
                            setErrorMessage('Error, file type not allowed');
                        } else {
                            setErrorMessage('');
                        }
                        setFilename(name || '');
                    }
                }}
            />
            <Button type="submit" disabled={!filename || isUploading || errorMessage}>
                {!isUploading ? 'Submit' : uploadMessage}
            </Button>
        </form>
    );
};

export default FileInput;
