import React, { useState, useEffect } from 'react';
import { useInterval } from 'hooks';
import { useViewlogies } from 'context';
import { scheduleAtTime } from 'utils';
import ViewlogyLogin from '../Login';
import VideoPlayer from './VideoPlayer';
import Countdown from './Countdown';
import VideoMessage from './VideoMessage';
import MobilePlaceholder from './MobilePlaceholder';
import Overlay from './Overlay';
import './videowrapper.scss';
import CallToAction from './CallToAction';

const _60_SECONDS = 60 * 1000;

const VideoWrapper = ({ children, showCallToAction }) => (
    <>
        <div className="video-wrapper"> {children}</div>
        {showCallToAction ? (
            <div className="video-footer">
                <div className="callToActionContainer">
                    <CallToAction />
                </div>
                <div className="videoMessageContainer">
                    <VideoMessage />
                </div>
                <div className="callToActionContainer" />
            </div>
        ) : (
            <VideoMessage />
        )}
    </>
);

const VideoPlayerContainer = () => {
    const { event, streams, streamIndex, refreshEvent } = useViewlogies();

    const [showCountdown, setShowCountdown] = useState(true);
    const [showOverlay, setShowOverlay] = useState(true);
    const [showMobilePlaceholder, setShowMobilePlaceholder] = useState(false);
    const [startTime, setStartTime] = useState(null); // start time of current or next upcoming stream

    const setMobilePlaceholder = _startTime => {
        if (streams.length) {
            const _isMobile = streams[streamIndex].streamType === 'Mobile Stream' ?? false;
            const _hasVideoUrl = !!streams[streamIndex].videoUrl;
            const _shouldHaveVideoUrl = new Date().getTime() > _startTime.getTime() - _60_SECONDS;

            setShowMobilePlaceholder(_isMobile && !_hasVideoUrl && _shouldHaveVideoUrl);
        }
    };

    useEffect(() => {
        if (startTime) {
            scheduleAtTime(startTime.getTime() - _60_SECONDS, [() => setMobilePlaceholder(startTime)]);
            if (showCountdown) {
                scheduleAtTime(startTime.getTime() - _60_SECONDS, [() => setShowCountdown(false)]);
            }
        }
    }, [startTime]);

    useEffect(() => {
        if (streams.length) {
            // set startTime and showCountdown in state, based on current stream start time
            const _startTime = new Date(streams[streamIndex].startTime);
            setMobilePlaceholder(_startTime);
            setShowCountdown(new Date().getTime() <= _startTime.getTime() - _60_SECONDS);
            setStartTime(_startTime);
        }
    }, [streams, streamIndex]);

    const FETCH_INTERVAL = 10000;
    const _refreshEvent = async _showMobilePlaceholder => {
        if (_showMobilePlaceholder) {
            await refreshEvent();
        }
    };
    useInterval(() => {
        (async () => await _refreshEvent(showMobilePlaceholder))();
    }, FETCH_INTERVAL);

    if (!streams.length || !startTime) {
        return null;
    }

    const stream = streams[streamIndex];
    const _isLoggedIn = event.isLoggedIn || stream.isLoggedIn;

    const _anyStreamLoggedIn = streams.some(stream => stream.isLoggedIn);
    const _slideshowVisible = stream.id === 'TRIBUTE_VIDEO' && (_anyStreamLoggedIn || _isLoggedIn);

    if (!_isLoggedIn && !_slideshowVisible) {
        return (
            <>
                <ViewlogyLogin />
            </>
        );
    }
    return (
        <VideoWrapper showCallToAction={!showCountdown}>
            {showMobilePlaceholder && stream.id !== 'TRIBUTE_VIDEO' && (
                <MobilePlaceholder hide={() => setShowMobilePlaceholder(false)} hidden={!showMobilePlaceholder} />
            )}
            {showCountdown && stream.id !== 'TRIBUTE_VIDEO' && (
                <Countdown date={startTime} hide={() => setShowCountdown(false)} hidden={!showCountdown} />
            )}

            {showOverlay && !showCountdown && !showMobilePlaceholder && stream.id !== 'TRIBUTE_VIDEO' && (
                <Overlay
                    name={event.firstName + ' ' + event.lastName}
                    description={stream.name}
                    hidden={!showOverlay}
                    color={event.color}
                />
            )}
            <VideoPlayer hidden={(showCountdown || showMobilePlaceholder) && stream.id !== 'TRIBUTE_VIDEO'} />
        </VideoWrapper>
    );
};

export default VideoPlayerContainer;
