import axios from 'axios';
import config from '../config/default';

/**
 * Request event
 * Either use new endpoint or legacy endpoint depending on function parameters
 * @param {String} organizationId - id of the organization
 * @param {String} eventId - id of the event
 * @param {String} chapel - name of the chapel
 * @param {String} startTime - startTime string that can be converted into date (UTC)
 * @param {String} lastName - last name of the deceased
 * @return {Promise<{message: string, status: number}|{message: string, status: number, data: object}>}
 * @private
 */
export const getEvent = async ({ organizationId, eventId, chapel, startTime, lastName }) => {
    // if there's no eventId assume calling legacy URL
    try {
        // get URL
        let url = '';
        if (eventId) {
            url = `${config.viewlogiesApiUrl}/client/v2/event/${organizationId}/${eventId}`;
        } else if (chapel && startTime && lastName) {
            url = `${config.viewlogiesApiUrl}/client/v2/event/${organizationId}/${chapel}/${startTime}/${lastName}`;
        } else {
            console.error('Invalid URL.');
            return { status: -1, message: 'Invalid URL.' };
        }

        const { status, data } = await axios({
            method: 'get',
            url,
            headers: { Accept: 'application/json' },
            withCredentials: true
        });
        return { status, data, message: 'Success!' };
    } catch (e) {
        console.error(e);
        const { status, data } = e.response;
        return { status, message: data.message || e.message };
    }
};

/**
 * Check if user is authorized by PIN
 * Requires cookies to be passed to API
 * @param {String} organizationId - id of the organization
 * @param {String} eventId - id of the event
 * @return {Promise<{status: number, message: string, isAuthorized: boolean}>}
 */
export const getEventLogin = async (organizationId, eventId) => {
    try {
        const { status, data } = await axios({
            method: 'get',
            url: `${config.viewlogiesApiUrl}/client/event/login/${organizationId}/${eventId}`,
            headers: { Accept: 'application/json' },
            withCredentials: true
        });
        return { status, isAuthorized: true, message: data.message };
    } catch (e) {
        console.error(e);
        const { status, data } = e.response;
        return { status, isAuthorized: false, message: data.message || e.message };
    }
};

export const getEventByUploadToken = async (organizationId, eventId, token) => {
    try {
        const { status, data } = await axios({
            method: 'get',
            url: `${config.viewlogiesApiUrl}/client/event/upload/${organizationId}/${eventId}/${token}`,
            headers: { Accept: 'application/json' },
            withCredentials: true
        });
        return { status, data };
    } catch (e) {
        console.error(e);
        const { status, data } = e.response;
        return { status, message: data.message || e.message };
    }
};

export const createNewUpload = async (organizationId, eventId, token, fileBytes) => {
    try {
        const { status, data } = await axios({
            method: 'post',
            url: `${config.viewlogiesApiUrl}/client/event/upload/${organizationId}/${eventId}`,
            params: { token: token },
            data: { fileBytes: fileBytes },
            headers: { 'Content-Type': 'application/json', Accept: '*/*' },
            withCredentials: true
        });
        return { status, data };
    } catch (e) {
        console.error(e);
        const { status, data } = e.response;
        return { status, message: data.message || e.message };
    }
};

export const getUploadStatus = async (organizationId, eventId, token) => {
    try {
        const { status, data } = await axios({
            method: 'get',
            url: `${config.viewlogiesApiUrl}/client/event/upload/${organizationId}/${eventId}/status`,
            params: { token: token },
            headers: { Accept: 'application/json' },
            withCredentials: true
        });
        return { status, data };
    } catch (e) {
        console.error(e);
        const { status, data } = e.response;
        return { status, message: data.message || e.message };
    }
};
