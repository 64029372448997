import React from 'react';
import Header from 'components/Header';
import './centeredContentContainer.scss';

/**
 * Container for content centered in a fixed position horizontally and vertically in the middle of the screen
 * @param {String} type - whether the centered content is in 'default' position or 'top' position
 * @param {Element[]} children - content
 * @return {JSX.Element}
 * @constructor
 */
const CenteredContentContainer = ({ type = 'default', children }) => {
    let contentClass = '';
    switch (type) {
        case 'top':
            contentClass = 'top-centered-content';
            break;
        case 'default':
        default:
            contentClass = 'centered-content';
    }

    return (
        <div className="centered-content-container">
            <Header />
            <div className={contentClass}>{children}</div>
        </div>
    );
};

export default CenteredContentContainer;
