import React, { useState } from 'react';
import './resources.scss';

const Card = ({ imageSrc, color, title, description, link, linkText }) => {
    const [hover, setHover] = useState(false);

    return (
        <section className="resources-card">
            <img src={imageSrc} alt="" />
            <div className="resources-card-text">
                <h2>{title}</h2>
                <p>{description}</p>
                <div className="resources-link-container">
                    <a
                        className="viewlogies-button resources-link"
                        href={link}
                        target="_blank"
                        rel="noopen noreferrer"
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        style={{
                            color: hover ? color : '#fff',
                            border: `2px solid ${color}`,
                            backgroundColor: hover ? 'unset' : color
                        }}
                    >
                        {linkText}
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Card;
