import Button from 'components/Button';
import { useState } from 'react';

const SubmitButton = ({ intl, isSubmitDisabled, color }) => {
    const [textColor, setTextColor] = useState(color);
    const [bgColor, setBgColor] = useState('unset');

    return (
        <>
            <Button
                data-sitekey="6LeBiG0aAAAAAL9IidP5LsSMl-PNR_f-GxzOv-TF"
                type="submit"
                disabled={isSubmitDisabled}
                text={intl.formatMessage({
                    id: 'features.Viewlogy.GuestSection.Form.signTheList',
                    defaultMessage: 'Get Updates & Sign guest list'
                })}
                className="g-recaptcha guest-submit-button"
                onMouseEnter={() => {
                    if (!isSubmitDisabled) {
                        setBgColor(color);
                        setTextColor('#fff');
                    }
                }}
                onMouseLeave={() => {
                    if (!isSubmitDisabled) {
                        setBgColor('#fff');
                        setTextColor(color);
                    }
                }}
                style={{
                    color: textColor,
                    border: `2px solid ${color}`,
                    backgroundColor: bgColor,
                    opacity: isSubmitDisabled ? '.6' : 'unset',
                    cursor: isSubmitDisabled ? 'default' : 'pointer'
                }}
            />
        </>
    );
};

export default SubmitButton;
