import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './overlay.scss';

const Overlay = ({ name, description, hidden = false, color = 'black' }) => (
    <div className={`overlay-container${hidden ? ' hidden' : ''}`}>
        <div className="overlay fadeOut">
            <span className="name">
                <h2
                    style={{
                        color: color
                    }}
                >
                    <FormattedMessage id="features.Viewlogy.VideoPlayer.Overlay.text" defaultMessage={name} />
                </h2>
            </span>
            <span className="description">
                <h2
                    style={{
                        backgroundColor: color,
                        border: `4px solid ${color}`
                    }}
                >
                    <FormattedMessage id="features.Viewlogy.VideoPlayer.Overlay.text" defaultMessage={description} />
                </h2>
            </span>
        </div>
    </div>
);

export default injectIntl(Overlay);
