import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useViewlogies } from 'context';
import './contactUs.scss';

const refresh = () => window.location.reload(true);

const ContactUs = () => {
    const {
        event: { organizationName, color = '#16394e' }
    } = useViewlogies();

    return (
        <section className="contact-us">
            <hr />
            <div className="contact-us-content">
                <h3>
                    <FormattedMessage
                        id="components.ContactUs.title"
                        defaultMessage="Need technical support or help with the video?"
                    />
                </h3>
                <p>
                    <FormattedMessage
                        id="components.ContactUs.description"
                        defaultMessage="Please try the following steps for immediate help. <a>Refresh</a> the website to reconnect the Private Broadcast™. Visit our <b>Help Center</b>. Contact our support team."
                        values={{
                            a: chunks => (
                                <a href="" onClick={refresh}>
                                    {chunks}
                                </a>
                            ),
                            b: chunks => <a href="https://www.viewlogies.com/help-center">{chunks}</a>
                        }}
                    />
                </p>
                <h2 style={{ color }}>{`(415) 763-8439 / support@viewlogies.com`}</h2>
                <p>
                    <FormattedMessage
                        id="components.ContactUs.note"
                        defaultMessage="{1} video streaming and recording is powered by Viewlogies. Call or email us now!"
                        values={{ 1: organizationName }}
                    />
                </p>
            </div>
            <hr />
        </section>
    );
};

export default ContactUs;
