const Header = ({ intl, lastName, eventStarted, color, isFormSubmitted, isSubmitting }) => {
    const title =
        isFormSubmitted || isSubmitting
            ? eventStarted
                ? intl.formatMessage(
                      {
                          id: 'features.Viewlogy.GuestSection.Form.thankyou',
                          defaultMessage: 'Thank you for supporting the {1} family'
                      },
                      { 1: lastName }
                  )
                : intl.formatMessage({
                      id: 'features.Viewlogy.GuestSection.Form.rsvpthankyou',
                      defaultMessage: "Thank you. We'll let you know of any updates."
                  })
            : eventStarted
            ? intl.formatMessage({ id: 'features.Viewlogy.GuestSection.Form.sign', defaultMessage: 'Sign Guest List' })
            : intl.formatMessage({ id: 'features.Viewlogy.GuestSection.Form.rsvp', defaultMessage: 'RSVP' });

    const firstSubtitleLine = eventStarted
        ? intl.formatMessage({
              id: 'features.Viewlogy.GuestSection.Form.signFirstSubtitle',
              defaultMessage: 'to show your support'
          })
        : intl.formatMessage({
              id: 'features.Viewlogy.GuestSection.Form.rsvpFirstSubtitle',
              defaultMessage: 'to receive new updates'
          });

    const secondSubtitleLine = eventStarted
        ? intl.formatMessage({
              id: 'features.Viewlogy.GuestSection.Form.signSecondSubtitle',
              defaultMessage: 'and that you attended'
          })
        : intl.formatMessage({
              id: 'features.Viewlogy.GuestSection.Form.rsvpSecondSubtitle',
              defaultMessage: 'about the livestream'
          });

    return (
        <>
            <div>
                <p
                    className="video-message guest-form-header"
                    style={{ color, marginBottom: !isFormSubmitted && !isSubmitting ? '5px' : undefined }}
                >
                    {title}
                </p>
                {!isFormSubmitted && !isSubmitting && (
                    <p className="video-message guest-form-subtitle" style={{ color }}>
                        {firstSubtitleLine}
                        <br />
                        {secondSubtitleLine}
                    </p>
                )}
            </div>
        </>
    );
};

export default Header;
