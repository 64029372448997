import React from 'react';
import lockImage from 'assets/lock.png';
import './loginContainer.scss';

const LoginContainer = ({ children, className, imageSrc }) => (
    <main className={`login-container${className ? ` ${className}` : ''}`}>
        <div className="lock-container">
            <img className="lock" alt="" src={imageSrc || lockImage} />
        </div>
        {children}
    </main>
);

export default LoginContainer;
