import React from 'react';
import PropTypes from 'prop-types';
import m from 'messages';
import './header.scss';

const Header = ({ type = 'black' }) => (
    <header className={`header header-${type}`}>
        <h1>
            <a href="https://viewlogies.com">{m.viewlogies}</a>
        </h1>
    </header>
);

Header.propTypes = {
    type: PropTypes.oneOf(['black', 'black-no-background', 'white'])
};

export default Header;
