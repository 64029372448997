import React, { useState, useEffect } from 'react';
import { parse } from 'query-string';
import { getEventByUploadToken } from 'services/event';
import Header from 'components/Header';
import Loader from 'components/Loader';
import SlideshowUpload from './SlideshowUpload';
import 'index.scss';
import './slideshowUpload.scss';

const SlideshowUploadContainer = ({ match: { params }, location, intl }) => {
    const { organizationId, eventId } = params;
    const { token } = parse(location.search);

    const [event, setEvent] = useState(null);

    useEffect(() => {
        getEventByUploadToken(organizationId, eventId, token)
            .then(({ data }) => setEvent(data))
            .catch(err => {
                console.error(err);
                // redirect to 404 page
                window.location.href = 'https://www.viewlogies.com/404';
            });
    }, []);

    return (
        <div className="action-container slideshow-upload-container">
            <Header type="black-no-background" />
            {event === null ? (
                <Loader />
            ) : (
                <SlideshowUpload organizationId={organizationId} event={event} token={token} />
            )}
        </div>
    );
};

export default SlideshowUploadContainer;
