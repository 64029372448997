import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { getCookie, setCookie } from 'utils';
import './videoPlayer.scss';

const vimeoAutoplayConfig = {
    vimeo: {
        playerOptions: {
            autopause: false,
            autoplay: true,
            playsinline: true
        }
    }
};

const VideoPlayer = ({ url, thumbnailImageUrl = '', hidden = false, introVideoUrl = null, streamId = null }) => {
    const [videoUrl, setVideoUrl] = useState('');
    const [showIntroVideo, setShowIntroVideo] = useState(true);
    const [videoConfig, setVideoConfig] = useState({});

    useEffect(() => {
        if (streamId && introVideoUrl && showIntroVideo && getCookie(`WATCHED_INTRO_VIDEO_${streamId}`) === '') {
            setVideoUrl(introVideoUrl);
        } else {
            setShowIntroVideo(false);
            setVideoUrl(url);
        }
    }, [url]);

    const onEnded = () => {
        if (showIntroVideo && videoUrl === introVideoUrl) {
            setCookie(`WATCHED_INTRO_VIDEO_${streamId}`, 'true', 1);
            setVideoConfig(vimeoAutoplayConfig);
            setVideoUrl(url);
            setShowIntroVideo(false);
        }
    };

    return (
        <div className={`player-wrapper${hidden ? ' hidden' : ''}`}>
            <ReactPlayer
                url={videoUrl}
                onEnded={onEnded}
                light={thumbnailImageUrl || false}
                controls
                className="react-player"
                width="100%"
                height="100%"
                config={videoConfig}
            />
        </div>
    );
};

export default VideoPlayer;
