import { useViewlogies } from 'context';
import React, { useState } from 'react';
import './calltoaction.scss';

const getTreeLink = (obituaryUrl, organizationId) => {
    try {
        const queryParams = obituaryUrl ? new URLSearchParams(new URL(obituaryUrl).search) : undefined;
        const obituaryId = queryParams.get('obId') || queryParams.get('oId');

        let treeLink = '';
        if (obituaryId) {
            treeLink = `https://tree.tributestore.com/memorial-tree?oId=${obituaryId}`;
        } else {
            return '';
        }

        if (organizationId) {
            treeLink = `${treeLink}&utm_source=${organizationId}-viewlogies`;
        } else {
            treeLink = `${treeLink}&utm_source=viewlogies`;
        }

        treeLink += '&utm_medium=referral&utm_campaign=tt_viewlogies&utm_content=plant-a-tree';
        return treeLink;
    } catch (e) {
        return undefined;
    }
};

const CallToAction = () => {
    const [hover, setHover] = useState(false);
    const { event } = useViewlogies();
    const treeLink = getTreeLink(event.obituaryUrl, event.organizationId);
    const plantATree = event.videoCTA === 'Plant A Tree Tribute Store';
    return (
        <>
            {treeLink && plantATree && (
                <div className={'callToAction'}>
                    <button
                        className={'ctaButton'}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        style={{
                            color: hover ? event.color : '#fff',
                            border: `2px solid ${event.color}`,
                            backgroundColor: hover ? '#fff' : event.color
                        }}
                    >
                        <a
                            href={treeLink}
                            target="_blank"
                            rel="noopen noreferrer"
                            style={{ color: hover ? event.color : '#fff', textDecoration: 'none' }}
                        >
                            <div className={'ctaTextContainer'}>
                                <p className={'ctaText ctaTitle'}>Plant a Tree</p>
                                {event.firstName && (
                                    <p className={'ctaText ctaSubtitle'}>in memory of {event.firstName}</p>
                                )}
                            </div>
                        </a>
                    </button>
                </div>
            )}
        </>
    );
};

export default CallToAction;
