import React from 'react';

const helpCenter = 'Help Center';
const refresh = 'Refresh';

export default {
    refresh:
        'Please refresh your page if the stream does not start on time or if you lose connection to the stream at any time.',
    subTitle: 'Enter The Password And Click “Watch Video”',
    recorded: 'This service is being recorded as a keepsake.',
    rewatch: 'Please come back anytime to re-watch.',
    contactUsTitle: 'Need technical support or help with the video?',
    contactUsDescription: [
        'Please try the following steps for immediate help. ',
        <a href="" onClick={() => window.location.reload(true)}>
            {refresh}
        </a>,
        ' the website to reconnect the Private Broadcast™. Visit our ',
        <a href="https://www.viewlogies.com/help-center">{helpCenter}</a>,
        '. Contact our support team.'
    ],
    contactUsNote: 'video streaming and recording is powered by Viewlogies. Call or email us now!',
    obituaryPageIntro: 'Don’t forget about Family Obituary Pages',
    obituaryPageTitle: 'Memorialize Your Loved One',
    obituaryPageDescription:
        'Upload your memories, photos, and family artifacts to a permanent family website brought to you by',
    obituaryPage: 'Obituary Page',
    loginHelp: 'Password is all lowercase including first letter, please verify your caps lock is off.'
};
