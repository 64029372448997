import ar from 'compiled-lang/ar.json';
import en from 'compiled-lang/en.json';
import es from 'compiled-lang/es.json';
import fr from 'compiled-lang/fr.json';
import hi from 'compiled-lang/hi.json';
import pa from 'compiled-lang/pa.json';
import pt from 'compiled-lang/pt.json';
import ro from 'compiled-lang/ro.json';
import ru from 'compiled-lang/ru.json';
import tl from 'compiled-lang/tl.json';
import vi from 'compiled-lang/vi.json';
import zh from 'compiled-lang/zh.json';
import fa from 'compiled-lang/fa.json';

export default { ar, en, es, fa, fr, hi, pa, pt, ro, ru, tl, vi, zh };
