import axios from 'axios';
import config from 'config/default'; // TODO: fix config

const { viewlogiesApiUrl } = config;

export const search = pin => {
    return axios({
        method: 'post',
        url: `${viewlogiesApiUrl}/client/v2/search`,
        data: { pin }
    });
};

export const searchDownload = pin => {
    return axios({
        method: 'get',
        url: `${viewlogiesApiUrl}/client/v2/search/download/${pin}`
    });
};
