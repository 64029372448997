import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import LoginContainer from './LoginContainer';
import './login.scss';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            showSubmit: false
        };

        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    handlePasswordChange(event) {
        const { value: password } = event.target;
        const { setLoginError } = this.props;
        this.setState({ password });
        if (password === '') {
            this.setState({ showSubmit: false });
            setLoginError(false);
        } else {
            this.setState({ showSubmit: true });
        }
    }

    render() {
        const { onSubmit, loginError, helpMessage, inputType, intl } = this.props;
        const { password, showSubmit } = this.state;
        const submit = ev => {
            onSubmit(password);
            ev.preventDefault();
        };

        return (
            <LoginContainer className="login-form">
                <form onSubmit={submit}>
                    {helpMessage && <p>{helpMessage}</p>}
                    <input
                        type={inputType}
                        name="password"
                        placeholder={intl.formatMessage({ id: 'password', defaultMessage: 'Password' })}
                        onChange={this.handlePasswordChange}
                        autoCorrect="off"
                        autoCapitalize="none"
                    />
                    {showSubmit ? (
                        <i
                            className="fas fa-chevron-right submit-icon"
                            onClick={submit}
                            tabIndex={0}
                            role="button"
                            aria-label="sign in"
                        />
                    ) : null}
                </form>
                <p className={`login-error${loginError ? '' : ' error-hidden'}`}>
                    <FormattedMessage id="components.Login.loginError" defaultMessage="Please try again." />
                </p>
            </LoginContainer>
        );
    }
}

Login.defaultProps = {
    inputType: 'password',
    helpMessage: ''
};

Login.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    loginError: PropTypes.bool.isRequired,
    setLoginError: PropTypes.func.isRequired,
    helpMessage: PropTypes.string,
    inputType: PropTypes.string
};

export default injectIntl(Login);
