import { Form } from 'formik';
import InputFields from './InputFields';
import ReCaptcha from './ReCaptcha';
import SubmitButton from './SubmitButton';
import Disclaimers from './Disclaimers';
import validator from 'validator';

const SubmissionForm = ({
    values,
    touched,
    isSubmitting,
    recaptchaRef,
    setMessage,
    isRecaptchaError,
    message,
    intl,
    color
}) => {
    const isInvalidName = touched.name && !values.name;
    const isInvalidEmail = touched.email && !validator.isEmail(values.email);
    const isSubmitDisabled = !values.name || !values.email || isInvalidName || isInvalidEmail || isSubmitting;
    return (
        <Form>
            <div className="guest-form">
                <InputFields values={values} isInvalidName={isInvalidName} isInvalidEmail={isInvalidEmail} />
                <ReCaptcha
                    isSubmitDisabled={isSubmitDisabled}
                    recaptchaRef={recaptchaRef}
                    setMessage={setMessage}
                    isRecaptchaError={isRecaptchaError}
                />
                {message && !isRecaptchaError && <div className="error-message">{message}</div>}
                <SubmitButton intl={intl} isSubmitDisabled={isSubmitDisabled} color={color} />
                <Disclaimers />
            </div>
        </Form>
    );
};

export default SubmissionForm;
