import React, { useEffect, useRef } from 'react';

export const useInterval = (fn, delay) => {
    const savedCallback = useRef();

    // Remember the latest fn.
    useEffect(() => {
        savedCallback.current = fn;
    }, [fn]);

    // Set up the interval.
    useEffect(() => {
        let id = setInterval(() => {
            savedCallback.current();
        }, delay);
        return () => clearInterval(id);
    }, [delay]);
};
