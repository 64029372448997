const getViewlogiesApiUrl = () => {
    switch (process.env.REACT_APP_ENVIRONMENT) {
        case 'local':
            return 'http://localhost:3000';
        case 'stage':
            return 'https://stage.viewlogies-dev.com';
        default:
            return 'https://viewlogies-dev.com';
    }
};

export default {
    viewlogiesApiUrl: getViewlogiesApiUrl(),
    redirectUrl: 'https://viewlogies.com/404',
    googlePlaceIdReviewUrl: 'https://search.google.com/local/writereview?placeid=',
    recaptchaSiteKey: '6Ldlpm0aAAAAAL1yGg7LSA7AGHN8EF5UhV2K5wNL'
};
