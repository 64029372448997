import React from 'react';
import { useViewlogies } from 'context';
import EmbeddedVideoPlayer from 'components/VideoPlayer';
import './videoPlayer.scss';

const VideoPlayer = ({ hidden = false }) => {
    // get data from context
    let {
        streams,
        streamIndex,
        event: { introVideoUrl }
    } = useViewlogies();
    const stream = streams[streamIndex];

    return (
        <EmbeddedVideoPlayer url={stream.videoUrl} hidden={hidden} introVideoUrl={introVideoUrl} streamId={stream.id} />
    );
};

export default VideoPlayer;
