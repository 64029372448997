import React, { useState } from 'react';
import CodeInput from 'react-code-input';
import { FormattedMessage } from 'react-intl';
import LoginContainer from './LoginContainer';
import './PINLogin.scss';

// expected to be nested within IntlProvider context

const PINInput = ({ onSubmit, setError, imageSrc, isError, secondaryCta }) => {
    const [pin, setPin] = useState('');
    const [showHelpMessage, setShowHelpMessage] = useState(false);

    const handlePINChange = pin => {
        setPin(pin);
        if (pin.length === 6) {
            onSubmit(pin);
        } else {
            setError(false);
        }
    };

    const toggleHelpMessageDisplay = () => setShowHelpMessage(!showHelpMessage);

    return (
        <LoginContainer className="login-form" imageSrc={imageSrc}>
            <p className="input-cta">
                <FormattedMessage
                    id="components.Login.PINLogin.enterPIN"
                    defaultMessage="Please enter the service PIN"
                />
            </p>
            {secondaryCta && <p className="input-cta-secondary">{secondaryCta}</p>}
            <div className="input-container">
                <div className="input">
                    <div className="six-digit-input">
                        <CodeInput
                            name="pin"
                            type="number"
                            value={pin}
                            fields={6}
                            inputMode="numeric"
                            onChange={handlePINChange}
                        />
                        <div className="dash-separator">-</div>
                    </div>
                    <div className="help-button-container">
                        <p className={`pin-error${isError && pin.length === 6 ? '' : ' pin-error-hidden'}`}>
                            <FormattedMessage
                                id="features.Login.PINLogin.invalidPIN"
                                defaultMessage="Invalid PIN, please try again"
                            />
                        </p>
                        <button className="help-button" onClick={toggleHelpMessageDisplay}>
                            <FormattedMessage
                                id="features.Login.PINLogin.helpPIN"
                                defaultMessage="Do not have the PIN?"
                            />
                        </button>
                        <p className="help-button-message" style={{ display: showHelpMessage ? 'block' : 'none' }}>
                            <FormattedMessage
                                id="components.Login.PINLogin.contactInformant"
                                defaultMessage="Please contact the family informant to get the PIN"
                            />
                        </p>
                    </div>
                </div>
            </div>
        </LoginContainer>
    );
};

export default PINInput;
