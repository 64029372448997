import { defineMessages } from 'react-intl';

export default defineMessages({
    'Memorial Service': {
        id: 'features.Viewlogy.Navigation.Memorial Service',
        defaultMessage: 'Memorial Service'
    },
    'Celebration of Life': {
        id: 'features.Viewlogy.Navigation.Celebration of Life',
        defaultMessage: 'Celebration of Life'
    },
    Vigil: {
        id: 'features.Viewlogy.Navigation.Vigil',
        defaultMessage: 'Vigil'
    },
    'Life Remembered Service': {
        id: 'features.Viewlogy.Navigation.Life Remembered Service',
        defaultMessage: 'Life Remembered Service'
    },
    'Funeral Service': {
        id: 'features.Viewlogy.Navigation.Funeral Service',
        defaultMessage: 'Funeral Service'
    },
    'Funeral Reception': {
        id: 'features.Viewlogy.Navigation.Funeral Reception',
        defaultMessage: 'Funeral Reception'
    },
    'Family Farewell': {
        id: 'features.Viewlogy.Navigation.Family Farewell',
        defaultMessage: 'Family Farewell'
    },
    'Private Family Viewing': {
        id: 'features.Viewlogy.Navigation.Private Family Viewing',
        defaultMessage: 'Private Family Viewing'
    },
    'Public Viewing': {
        id: 'features.Viewlogy.Navigation.Public Viewing',
        defaultMessage: 'Public Viewing'
    },
    Rosary: {
        id: 'features.Viewlogy.Navigation.Rosary',
        defaultMessage: 'Rosary'
    },
    Mass: {
        id: 'features.Viewlogy.Navigation.Mass',
        defaultMessage: 'Mass'
    },
    'Virtual Prayer': {
        id: 'features.Viewlogy.Navigation.Virtual Prayer',
        defaultMessage: 'Virtual Prayer'
    },
    'Final Blessing': {
        id: 'features.Viewlogy.Navigation.Final Blessing',
        defaultMessage: 'Final Blessing'
    },
    Antyesti: {
        id: 'features.Viewlogy.Navigation.Antyesti',
        defaultMessage: 'Antyesti'
    },
    Levaya: {
        id: 'features.Viewlogy.Navigation.Levaya',
        defaultMessage: 'Levaya'
    },
    'Sikh Funeral': {
        id: 'features.Viewlogy.Navigation.Sikh Funeral',
        defaultMessage: 'Sikh Funeral'
    },
    Committal: {
        id: 'features.Viewlogy.Navigation.Committal',
        defaultMessage: 'Committal'
    },
    'Witness Cremation': {
        id: 'features.Viewlogy.Navigation.Witness Cremation',
        defaultMessage: 'Witness Cremation'
    },
    Trisagion: {
        id: 'features.Viewlogy.Navigation.Trisagion',
        defaultMessage: 'Trisagion'
    },
    Visitation: {
        id: 'features.Viewlogy.Navigation.Visitation',
        defaultMessage: 'Visitation'
    },
    Slideshow: {
        id: 'features.Viewlogy.Navigation.Slideshow',
        defaultMessage: 'Slideshow'
    },
    'Urn Placement': {
        id: 'features.Viewlogy.Navigation.Urn Placement',
        defaultMessage: 'Urn Placement'
    },
    'Special Event': {
        id: 'features.Viewlogy.Navigation.Special Event',
        defaultMessage: 'Special Event'
    }
});
