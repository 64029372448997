import React, { useEffect, useState } from 'react';
import { parse } from 'query-string';
import { postRating, postFeedback, postIsPreneedLinkClicked } from '../../services/guest';
import Header from 'components/Header';
import Button from 'components/Button';
import config from 'config/default';
import './feedback.scss';

const redirect = () => (window.location.href = config.redirectUrl);

const Feedback = ({ match: { params }, location }) => {
    const [guest, setGuest] = useState({});
    const [feedback, setFeedback] = useState('');
    const [submitResult, setSubmitResult] = useState({ success: false, message: '' });
    const [ratingResult, setRatingResult] = useState({ success: false, message: '', showForm: false });

    const { organizationId, eventId } = params;
    let { r: rating, p: preneedRedirecUrl, e: email } = parse(location.search);
    if (email) {
        email = atob(email.toString());
    }

    useEffect(() => {
        if (!email) {
            redirect();
        }
        const _guest = {
            email,
            rating,
            isPreneedLinkClicked: !!preneedRedirecUrl
        };
        setGuest(_guest);

        if (preneedRedirecUrl) {
            preneedRedirecUrl = atob(preneedRedirecUrl);
            postIsPreneedLinkClicked(eventId, organizationId, _guest)
                .then(() => {
                    console.log('redirecting');
                    window.location.href = preneedRedirecUrl;
                })
                .catch(() => {
                    window.location.href = preneedRedirecUrl;
                }); // ignore any situations where setting preneed url click event fails
        } else if (rating) {
            postRating(eventId, organizationId, _guest)
                .then(({ data: { googlePlaceId } }) => {
                    if (rating === '5') {
                        if (googlePlaceId) {
                            setRatingResult({
                                success: true,
                                message: 'You will be redirected shortly...',
                                showForm: false
                            });
                            window.location.href = `${config.googlePlaceIdReviewUrl}${googlePlaceId}`;
                        } else {
                            setRatingResult({
                                success: true,
                                message: 'Please let us know how we can improve.',
                                showForm: true
                            });
                        }
                    } else {
                        setRatingResult({
                            success: true,
                            message: 'How could we have made this a 5 star experience?',
                            showForm: true
                        });
                    }
                })
                .catch(err => {
                    console.error(err);
                    window.location.href = config.redirectUrl;
                });
        } else {
            redirect();
        }
    }, [rating]);

    const handleSubmit = e => {
        e.preventDefault();
        const _guest = { ...guest, feedback };
        setGuest(_guest);
        postFeedback(eventId, organizationId, _guest)
            .then(() => {
                setSubmitResult({
                    success: true,
                    message: 'Your feedback was submitted, thank you.'
                });
            })
            .catch(err => {
                console.error(err);
                setSubmitResult({
                    success: true,
                    message: 'There was an error submitting your feedback. Please try again.'
                });
            });
    };

    let mainContent = null;
    if (submitResult.success) {
        mainContent = (
            <div className="action-input">
                <hr />
                <p>{submitResult.message}</p>
            </div>
        );
    } else if (submitResult.message) {
        mainContent = (
            <div className="action-input">
                <hr />
                <p className="error-message">{submitResult.message}</p>
            </div>
        );
    } else if (ratingResult.success) {
        const message = (
            <>
                <hr />
                <p>{ratingResult.message}</p>
            </>
        );
        if (ratingResult.showForm) {
            mainContent = (
                <div className="action-input">
                    {message}
                    <form onSubmit={handleSubmit} className="action-input feedback-input">
                        <textarea value={feedback} onChange={ev => setFeedback(ev.target.value)} maxLength={999} />
                        <Button type="submit" disabled={feedback === ''}>
                            {'Submit'}
                        </Button>
                    </form>
                </div>
            );
        } else {
            mainContent = message;
        }
    }

    let intro = 'Loading...';
    if (preneedRedirecUrl) {
        intro = 'You will be redirected shortly.';
    } else if (rating) {
        intro = 'Thank you for submitting feedback.';
    }

    return (
        <div className="action-container">
            <Header type="black-no-background" />
            <div className="action">
                <h2>{intro}</h2>
                {mainContent}
            </div>
        </div>
    );
};

export default Feedback;
