import React from 'react';
import FadeLoader from 'react-spinners/FadeLoader';

const loaderStyles = `
    position: fixed;
    top: calc(50% + 30px);
    left: calc(50% + 30px);
    transform: translate(-50%, -50%);
`;

/**
 * Simple fade loader using react-spinners module
 * Loader takes up the whole screen
 */
function Loader(props) {
    return <FadeLoader css={loaderStyles} color={'#646464'} />;
}

export default Loader;
