import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useViewlogies } from 'context';

const VideoMessage = () => {
    const {
        event: { color }
    } = useViewlogies();

    return (
        <p className="video-message" style={{ color }}>
            <FormattedMessage
                id="features.Viewlogy.VideoPlayer.VideoMessage.recorded"
                defaultMessage="This service is being recorded as a keepsake."
            />
            <br />
            <FormattedMessage
                id="features.Viewlogy.VideoPlayer.VideoMessage.rewatch"
                defaultMessage="Please come back anytime to watch again."
            />
        </p>
    );
};

export default VideoMessage;
