import React from 'react';
import './footer.scss';
import { FormattedMessage } from 'react-intl';

const Footer = () => (
    <footer className="footer">
        <p className="emphasized">{'pronounced: /v • eulogies/'}</p>
        <p className="copyright">
            {/* TODO: add translation strings for below */}
            <a href="https://viewlogies.us/terms">{'Terms of Service'}</a>
            {' and '}
            <a href="https://viewlogies.us/privacy">{'Privacy Policy'}</a>
        </p>
        <p className="copyright">
            <FormattedMessage
                id="components.Footer.copyright"
                defaultMessage="Copyright © 2021 Viewlogies. All rights reserved."
            />
        </p>
    </footer>
);

export default Footer;
