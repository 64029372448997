import React, { useEffect } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useViewlogies } from 'context';
import Loader from 'components/Loader';
import Viewlogy from './Viewlogy';
import { parse } from 'query-string';

/**
 * Container component for Viewlogies event page
 * @param {Object} match - ReactRouter URL match object
 * @return {JSX.Element}
 * @constructor
 */
const ViewlogyContainer = ({ match: { params }, location }) => {
    const queryPin = parse(location.search).pin;
    const { fetchEvent, isLoading } = useViewlogies();
    // fetch event data from API
    useEffect(() => {
        (async () => fetchEvent(params))();
    }, []);
    // return loading animation while loading
    if (isLoading) return <Loader />;
    // return Viewlogies event page
    return <Viewlogy pin={queryPin} />;
};

ViewlogyContainer.propTypes = {
    match: ReactRouterPropTypes.match.isRequired
};

export default ViewlogyContainer;
