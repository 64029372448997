import React from 'react';
import { useViewlogies } from 'context';
import { FormattedMessage } from 'react-intl';
import m from './messages';
import './navigation.scss';

const Navigation = () => {
    const {
        event: { color },
        streams,
        streamIndex,
        setStreamIndex
    } = useViewlogies();

    const selectedStyle = {
        color,
        borderBottom: `2px solid ${color}`
    };

    return (
        <div className="streams-navigation">
            {streams.map((stream, index) => {
                const isSelected = index === streamIndex;
                const className = `stream-tab${isSelected ? ' selected' : ''}${streams.length === 1 ? ' single' : ''}`;
                return (
                    <div
                        className={className}
                        style={index === streamIndex ? selectedStyle : {}}
                        key={index}
                        onClick={() => setStreamIndex(index)}
                    >
                        <FormattedMessage {...m[stream.name]} />
                    </div>
                );
            })}
        </div>
    );
};

export default Navigation;
