import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { ViewlogyProvider } from 'context';
import Viewlogy from 'features/Viewlogy';
import Search from 'features/Search';
import SearchDownload from 'features/SearchDownload';
import Feedback from 'features/Feedback';
import SlideshowUpload from 'features/SlideshowUpload';
import Redirect404 from 'features/Redirect404';

// initialize google analytics
ReactGA.initialize('UA-66908667-1');

const PortalRedirect = ({
    match: {
        params: { organizationId }
    }
}) => {
    window.location.href = `https://portal.viewlogies.net/${organizationId}/login`;
    return null;
};

const App = () => (
    <div className="app-wrapper">
        <Helmet>
            <title>{'VIEWLOGIES'}</title>
        </Helmet>
        <ViewlogyProvider>
            <Router>
                <Switch>
                    <Route path="/:organizationId/schedule/login" component={PortalRedirect} />
                    <Route path="/:organizationId/schedule" component={PortalRedirect} />
                    <Route exact path="/locate" component={Search} />
                    <Route exact path="/download" component={SearchDownload} />
                    <Route exact path="/upload/:organizationId/:eventId" component={SlideshowUpload} />
                    <Route exact path="/feedback/:organizationId/:eventId" component={Feedback} />
                    <Route exact path="/:organizationId/:eventId" component={Viewlogy} />
                    <Route path="/:organizationId/:chapel/:startTime/:lastName" component={Viewlogy} />
                    <Route path="/" component={Redirect404} />
                </Switch>
            </Router>
        </ViewlogyProvider>
    </div>
);

export default App;
