import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';

const NAME_INPUT_MAX_LENGTH = 36; // this should keep inputs from being greater than 2 lines

const InputFields = ({ values, isInvalidName, isInvalidEmail }) => {
    return (
        <>
            <span className="placeholder">
                <FormattedMessage id="firstAndLastName" defaultMessage="First and Last Name" />
            </span>
            <Field
                name="name"
                className={`guest-form-input ${isInvalidName ? 'invalid' : ''}`}
                maxLength={NAME_INPUT_MAX_LENGTH}
            />
            {values.name.length >= NAME_INPUT_MAX_LENGTH && (
                <span className="field-error-message">
                    <FormattedMessage
                        id="features.Viewlogy.GuestSection.Form.inputTooLong"
                        defaultMessage="Input must be 36 characters or less."
                    />
                </span>
            )}
            <span className="placeholder">
                <FormattedMessage id="email" defaultMessage="Email" />
            </span>
            <Field
                name="email"
                type="email"
                className={`guest-form-input last-input ${isInvalidEmail ? 'invalid' : ''}`}
            />
        </>
    );
};

export default InputFields;
