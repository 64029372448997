import axios from 'axios';
import config from '../config/default';
import { getCookie, setCookie } from '../utils';

/**
 * Record guest insertion event in browser cookie
 * @param {String} eventId - id of the event
 */
export const getGuestCookie = eventId => getCookie(`${eventId}_guest_in`);

/**
 * Record guest insertion event in browser cookie
 * @param {String} eventId - id of the event
 */
export const setGuestCookie = eventId => setCookie(`${eventId}_guest_in`, true, 3650);

/**
 * Request event
 * Either use new endpoint or legacy endpoint depending on function parameters
 * @param {String} organizationId - id of the organization
 * @param {String} eventId - id of the event
 * @param {Number} page - index in database from where to load guests
 * @param {Number} size - number of guests to request
 * @return {Promise<{message: string, status: number}|{message: string, status: number, data: object}>}
 * @private
 */
export const getGuestList = async (organizationId, eventId, page, size) => {
    // if there's no eventId assume calling legacy URL
    try {
        const url = `${config.viewlogiesApiUrl}/client/event/${organizationId}/${eventId}/guest?index=${page}&size=${size}`;
        const { status, data } = await axios({
            method: 'get',
            url,
            headers: { Accept: 'application/json' },
            withCredentials: true
        });
        return { status, data, message: 'Success!' };
    } catch (e) {
        console.error(e);
        const { status, data } = e.response;
        return { status, message: data.message || e.message };
    }
};

/**
 * Add guest to guest list
 * @param {String} organizationId - id of the organization
 * @param {String} eventId - id of the event
 * @param {String} name - full name of guest
 * @param {String} email - email address of guest
 * @param {String} recaptchaToken - token from recaptcha for backend verification
 * @return {Promise<{message: string, status: number}|{message: *, status: number}>}
 */
export const insertGuest = async (eventId, organizationId, { name, email }, recaptchaToken) => {
    try {
        const url = `${config.viewlogiesApiUrl}/client/event/${organizationId}/${eventId}/guest`;
        const { status } = await axios({
            method: 'post',
            url,
            data: { name, email, recaptchaToken },
            headers: { Accept: 'application/json' },
            withCredentials: true
        });
        return { status, message: 'Success!' };
    } catch (e) {
        console.error(e);
        const { status, data } = e.response;
        return { status, message: data.message || e.message };
    }
};

export const postRating = async (eventId, organizationId, { email, rating }) =>
    await axios({
        method: 'post',
        url: `${config.viewlogiesApiUrl}/client/event/${organizationId}/${eventId}/guest/rating`,
        data: { email, rating },
        headers: { Accept: 'application/json' },
        withCredentials: true
    });

export const postFeedback = async (eventId, organizationId, { email, feedback }) =>
    await axios({
        method: 'post',
        url: `${config.viewlogiesApiUrl}/client/event/${organizationId}/${eventId}/guest/feedback`,
        data: { email, feedback },
        headers: { Accept: 'application/json' },
        withCredentials: true
    });

export const postIsPreneedLinkClicked = async (eventId, organizationId, { email, isPreneedLinkClicked }) =>
    await axios({
        method: 'post',
        url: `${config.viewlogiesApiUrl}/client/event/${organizationId}/${eventId}/guest/preneed`,
        data: { email, isPreneedLinkClicked },
        headers: { Accept: 'application/json' },
        withCredentials: true
    });
