import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import { search } from 'services/search';
import { setAuth } from 'services/viewlogyAuth';
import CenteredContentContainer from 'components/CenteredContentContainer';
import PINInput from 'components/Login/PINInput';
import Button from 'components/Button';
import searchImage from 'assets/search-icon.png';
import modalStyles from './modalStyles.json';
import './search.scss';

Modal.setAppElement('#root');

const Search = ({ intl }) => {
    const [event, setEvent] = useState(null);
    const [isLoginError, setIsLoginError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConsentedToProceed, setIsConsentedToProceed] = useState(false);
    const [isWrongEvent, setIsWrongEvent] = useState(false);

    const openModal = () => setIsModalOpen(true);

    const closeModal = () => setIsModalOpen(false);

    const proceedToEvent = () => {
        setIsWrongEvent(false);
        setIsConsentedToProceed(true);
    };

    const returnToStart = () => {
        setIsWrongEvent(true);
        closeModal();
    };

    const handleSubmit = pin => {
        search(pin)
            .then(({ data: event }) => {
                const { streamId } = event;
                setAuth(streamId, pin);
                setEvent(event);
                setIsLoginError(false);
                setIsWrongEvent(false);
                openModal();
            })
            .catch(e => {
                console.error(e);
                setIsLoginError(true);
                setEvent(null);
            });
    };

    if (isConsentedToProceed && event) {
        const { organizationId, id } = event;
        return <Redirect to={`/${organizationId}/${id}`} />;
    }

    let modalContent = null;
    if (event) {
        const { firstName, lastName } = event;
        const yes = intl.formatMessage({ id: 'yes', defaultMessage: 'yes' });
        const no = intl.formatMessage({ id: 'no', defaultMessage: 'no' });

        modalContent = (
            <>
                <div className="proceed-cta-message">
                    <FormattedMessage
                        id="features.Search.aboutToView"
                        defaultMessage="You are about to view <b>{1}</b> Private Broadcast™."
                        values={{
                            b: (...chunks) => <strong>{chunks}</strong>,
                            1: `${firstName} ${lastName}`
                        }}
                    />
                    <br />
                    <FormattedMessage
                        id="features.Search.isThisCorrect"
                        defaultMessage="Is this what you are looking for?"
                    />
                </div>
                <div className="proceed-cta-container">
                    <Button text={yes} onClick={proceedToEvent} />
                    <Button text={no} onClick={returnToStart} />
                </div>
            </>
        );
    }

    const secondaryCta = isWrongEvent ? (
        <FormattedMessage
            id="features.Search.pleaseVerify"
            defaultMessage="Please verify the pin is correct and try typing it again."
        />
    ) : (
        ''
    );

    return (
        <CenteredContentContainer>
            <PINInput
                onSubmit={handleSubmit}
                isError={isLoginError}
                setError={setIsLoginError}
                imageSrc={searchImage}
                secondaryCta={secondaryCta}
            />
            <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles}>
                {modalContent}
            </Modal>
        </CenteredContentContainer>
    );
};

export default injectIntl(Search);
