import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './mobileplaceholder.scss';

//TODO - add message to i18n file

const MobilePlaceholder = ({ intl, date, hide, hidden = false }) => (
    <div className={`mobileplaceholder-container${hidden ? ' hidden' : ''}`}>
        <div className="mobileplaceholder">
            <span>
                <h2>
                    <FormattedMessage
                        id="features.Viewlogy.VideoPlayer.MobilePlaceholder.willStartShortly"
                        defaultMessage="This Private Broadcast™ will start shortly"
                    />
                </h2>
            </span>
        </div>
        <div className="live-caption">
            <div className="circle" />
            <div>{'Live'}</div>
        </div>
    </div>
);

export default injectIntl(MobilePlaceholder);
