export const getMomentLocale = language => {
    switch (language) {
        case 'pa':
            return 'pa-in';
        case 'tl':
            return 'tl-ph';
        default:
            return language;
    }
};
