import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import * as tus from 'tus-js-client';
import { createNewUpload, getUploadStatus } from 'services/event';
import VideoPlayer from 'components/VideoPlayer';
import FileInput from './FileInput.js';
import 'index.scss';
import './slideshowUpload.scss';

const SlideshowUpload = ({
    organizationId,
    token,
    event: { id: eventId, firstName, lastName, slideshowVimeoUri, isUploadComplete },
    intl
}) => {
    // TODO: add i18n
    const [isUploading, setIsUploading] = useState(false);
    const [_isUploadComplete, _setIsUploadComplete] = useState(false);
    const [uploadPercent, setUploadPercent] = useState(0.0);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async file => {
        try {
            const {
                data: { uploadLink }
            } = await createNewUpload(organizationId, eventId, token, file.size);

            // create a new tus upload
            const upload = new tus.Upload(file, {
                // endpoint: uploadLink,
                uploadUrl: uploadLink,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                metadata: { filename: file.name, filetype: file.type },
                onError: err => {
                    setErrorMessage(`There was an error uploading ${file.name}`);
                    console.error(err);
                },
                onProgress: (bytesUploaded, bytesTotal) => {
                    setUploadPercent((bytesUploaded / bytesTotal) * 100);
                },
                onSuccess: async () => {
                    const {
                        data: { isComplete }
                    } = await getUploadStatus(organizationId, eventId, token);
                    if (isComplete) {
                        _setIsUploadComplete(true);
                        return;
                    }
                    setUploadPercent(0.0);
                }
            });

            // check if there are any previous uploads to continue
            const previousUploads = await upload.findPreviousUploads();
            if (previousUploads.length) {
                upload.resumeFromPreviousUpload(previousUploads[0]);
            }
            upload.start();
        } catch (err) {
            setErrorMessage(`There was an error uploading ${file.name}`);
            console.error(err);
        }
    };

    return (
        <div className="action slideshow-upload">
            <h2>
                <b>{`${firstName} ${lastName}`}</b>
                {' Private Broadcast™'}
                <hr />
                {isUploadComplete || _isUploadComplete ? 'Replace ' : 'Upload '}
                <em>{'Slideshow Tribute Video'}</em>
            </h2>
            {(isUploadComplete || _isUploadComplete) && !!slideshowVimeoUri && (
                <div>
                    <p>
                        {'Current '}
                        <em>{'Slideshow Tribute Video'}</em>
                    </p>
                    <div class="video-wrapper">
                        <VideoPlayer url={slideshowVimeoUri} />
                    </div>
                </div>
            )}
            <FileInput
                handleSubmit={handleSubmit}
                videoExists={_isUploadComplete}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                uploadPercent={uploadPercent}
                setUploadPercent={setUploadPercent}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
            />
        </div>
    );
};

export default injectIntl(SlideshowUpload);
