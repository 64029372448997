import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import LoginContainer from './LoginContainer';
import './PINLogin.scss';

// expected to be nested within IntlProvider context

const SlideshowMessage = () => {
    const [showHelpMessage, setShowHelpMessage] = useState(false);
    const toggleHelpMessageDisplay = () => setShowHelpMessage(!showHelpMessage);

    return (
        <LoginContainer className="login-form">
            <p className="input-cta">
                <FormattedMessage
                    id="components.Login.PINLogin.enterPINSlideshow"
                    defaultMessage="Please enter the PIN for any of the events to view this Slideshow."
                />
            </p>
            <div className="input-container">
                <div className="input">
                    <div className="help-button-container">
                        <button className="help-button" onClick={toggleHelpMessageDisplay}>
                            <FormattedMessage
                                id="features.Login.PINLogin.helpPINSlideshow"
                                defaultMessage="Do not have any PINs?"
                            />
                        </button>
                        <p className="help-button-message" style={{ display: showHelpMessage ? 'block' : 'none' }}>
                            <FormattedMessage
                                id="components.Login.PINLogin.contactInformantSlideshow"
                                defaultMessage="Please contact the family informant to get the PIN for your event."
                            />
                        </p>
                    </div>
                </div>
            </div>
        </LoginContainer>
    );
};

export default SlideshowMessage;
