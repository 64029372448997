import React from 'react';
import { useViewlogies } from 'context';
import Login from 'components/Login';
import PINInput from 'components/Login/PINInput';
import m from './messages';
import './login.scss';
import SlideshowMessage from 'components/Login/SlideshowMessage';

const ViewlogyLogin = () => {
    const {
        event: { globalPin },
        streams,
        streamIndex,
        videoLogin,
        messages: { login = { error: false } },
        setMessages
    } = useViewlogies();

    const setError = error => setMessages({ login: { error } });

    // check for pins (they should exist for all but legacy events)
    if (globalPin || streams[streamIndex].pin) {
        return (
            <div className="viewlogy-login">
                <PINInput onSubmit={videoLogin} isError={login.error} setError={setError} />
            </div>
        );
    }

    if (streams[streamIndex].id === 'TRIBUTE_VIDEO') {
        return (
            <div className="viewlogy-login">
                <SlideshowMessage />
            </div>
        );
    }

    // for legacy events
    return (
        <div className="viewlogy-login">
            <Login
                onSubmit={videoLogin}
                loginError={login.error}
                setLoginError={setError}
                helpMessage={m.loginHelp}
                inputType="text"
            />
        </div>
    );
};

export default ViewlogyLogin;
