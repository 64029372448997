import React from 'react';
import moment from 'moment-timezone';
// moment locales have to be imported explicitly :(
// TODO: add locale imports as needed
import 'moment/locale/ar';
import 'moment/locale/es';
import 'moment/locale/fa';
import 'moment/locale/fr';
import 'moment/locale/hi';
import 'moment/locale/pa-in';
import 'moment/locale/pt';
import 'moment/locale/ro';
import 'moment/locale/ru';
import 'moment/locale/tl-ph';
import 'moment/locale/vi';
import 'moment/locale/zh-cn';
import { getMomentLocale } from 'lang/util';
import { useViewlogies } from 'context';
import GuestForm from 'components/GuestForm';
import isEventStarted from 'components/GuestForm/isEventStarted';

const Subheader = () => {
    const {
        event,
        event: { firstName, lastName, timezone, isRsvpForm },
        streams,
        streamIndex,
        language
    } = useViewlogies();

    let time = '';

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (streams.length) {
        time = `: ${moment
            .utc(streams[streamIndex].startTime)
            .locale(getMomentLocale(language))
            .tz(userTimezone)
            .format('LLLL z')}`;
    }

    const eventStarted = isEventStarted(streams);

    return (
        <section className="subheader">
            <h3>{`${firstName} ${lastName}${time}`}</h3>
            {!eventStarted && isRsvpForm && <GuestForm />}
        </section>
    );
};

export default Subheader;
