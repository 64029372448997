import crypto from 'crypto';
import { getCookie, setCookie } from 'utils';

/**
 * Store client password as a cookie
 */
const _setAuthenticated = (eventId, pin) => setCookie(`${eventId}_pin`, pin);

/**
 * Get client password from cookie
 */
const _getPIN = eventId => getCookie(`${eventId}_pin`);

/**
 * Sha1 encrypt input string
 * @param {String} pin - the pin (user input or cookie value)
 * @private
 */
const _encryptPIN = pin =>
    crypto
        .createHash('sha1')
        .update(pin)
        .digest('hex');

/**
 * Store client password as a cookie
 */
const _setAuthenticated__legacy = (eventId, firstName) => setCookie(`${eventId}_${firstName.toLowerCase()}_in`, true);

/**
 * Get client password from cookie
 */
const _getIsAuthenticated__legacy = (eventId, firstName) => getCookie(`${eventId}_${firstName.toLowerCase()}_in`);

export function setAuth(eventId, pin) {
    _setAuthenticated(eventId, _encryptPIN(pin));
}

export function authenticate({ pin, eventId, firstName }, input) {
    if (pin) {
        if (pin === _encryptPIN(input)) {
            _setAuthenticated(eventId, pin);
            return true;
        }
        return false;
    }
    // support for legacy events (use first name from cookie)
    if (firstName.toLowerCase() === input) {
        _setAuthenticated__legacy(eventId, firstName);
        return true;
    }
    return false;
}

/**
 * Check if user is authenticated
 * @param {String} pin - encrypted pin
 * @param {String} id - eventId or streamId
 * @param {String} firstName - a user's first name (only needed for legacy users)
 * @return {boolean}
 */
export function isAuthenticated({ pin, id, firstName }) {
    if (pin) {
        return pin === _getPIN(id);
    }
    // support for legacy events (use first name from cookie)
    return !!_getIsAuthenticated__legacy(id, firstName);
}
