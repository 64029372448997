import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Modal from 'react-modal';
import { searchDownload } from 'services/search';
import CenteredContentContainer from 'components/CenteredContentContainer';
import PINInput from 'components/Login/PINInput';
import searchImage from 'assets/download.png';
import modalStyles from './modalStyles.json';
import './searchDownload.scss';

Modal.setAppElement('#root');

const SearchDownload = () => {
    const [event, setEvent] = useState(null);
    const [streams, setStreams] = useState([]);
    const [isLoginError, setIsLoginError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);

    const closeModal = () => setIsModalOpen(false);

    const handleSubmit = pin => {
        searchDownload(pin)
            .then(({ data: { event, streams } }) => {
                setEvent(event);
                setStreams(streams);
                setIsLoginError(false);
                openModal();
            })
            .catch(e => {
                console.error(e);
                setIsLoginError(true);
                setEvent(null);
                setStreams([]);
            });
    };

    let modalContent = null;
    if (event) {
        const { firstName, lastName } = event;

        modalContent = (
            <>
                <div className="download-cta-message">
                    {streams && streams.length > 0 ? (
                        <FormattedMessage
                            id="features.Search.aboutToDownload"
                            defaultMessage={`You can download <b>{1}</b> Private Broadcast™ by clicking the button${
                                streams.length !== 1 ? 's' : ''
                            } below.`}
                            values={{
                                b: (...chunks) => <strong>{chunks}</strong>,
                                1: `${firstName} ${lastName}`
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="features.Search.noDownloadsFound"
                            defaultMessage="No recordings were found for <b>{1}</b> Private Broadcast™. Please contact support@viewlogies.com"
                            values={{
                                b: (...chunks) => <strong>{chunks}</strong>,
                                1: `${firstName} ${lastName}`
                            }}
                        />
                    )}
                </div>
                <div className="download-cta">
                    {streams.map(s => (
                        <a
                            key={s.name}
                            className="viewlogies-button download-cta-button"
                            href={s.downloadLink}
                            download
                        >
                            {s.name}
                        </a>
                    ))}
                </div>
            </>
        );
    }

    return (
        <CenteredContentContainer>
            <PINInput
                onSubmit={handleSubmit}
                isError={isLoginError}
                setError={setIsLoginError}
                imageSrc={searchImage}
            />
            <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles}>
                {modalContent}
            </Modal>
        </CenteredContentContainer>
    );
};

export default injectIntl(SearchDownload);
