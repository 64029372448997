import React from 'react';
import { useViewlogies } from 'context';
import './header.scss';

const Header = () => {
    const {
        event: { organizationName, logoUrl }
    } = useViewlogies();

    return (
        <header className="client-header">
            <img src={logoUrl} alt={organizationName} />
            <hr />
        </header>
    );
};

export default Header;
