import React, { createContext, useContext, useReducer } from 'react';
import { IntlProvider } from 'react-intl';
import messages from 'lang/messages';
import { authenticate } from 'services/viewlogyAuth';
import { getEvent } from 'services/event';
import { getGuestList } from 'services/guest';

const Context = createContext();

const GUEST_LIST_MIN_SIZE = 4;

const initialState = {
    urlParams: undefined,
    isLoading: true,
    event: {
        isLoggedIn: false,
        isLoginError: false
    },
    isPinLoggedIn: false, // set to true if at least one PIN is logged in
    guestList: [],
    guestListSize: 0,
    displaySize: GUEST_LIST_MIN_SIZE,
    streams: [],
    streamIndex: 0,
    messages: {},
    language: navigator.language.split(/[-_]/)[0] || 'en'
};

const reducer = (state, action) => {
    // update state through reducer actions
    switch (action.type) {
        case 'UPDATE_URL_PARAMS':
            return {
                ...state,
                urlParams: action.payload
            };
        case 'UPDATE_IS_LOADING':
            return {
                ...state,
                isLoading: action.payload
            };
        case 'UPDATE_IS_LOGGED_IN':
            return {
                ...state,
                event: {
                    ...state.event,
                    isLoggedIn: action.payload
                }
            };
        case 'UPDATE_EVENT':
            const { streams, ...event } = action.payload;
            return {
                ...state,
                event: {
                    ...state.event,
                    ...event
                },
                streams
            };
        case 'UPDATE_STREAMS':
            return {
                ...state,
                streams: action.payload
            };
        case 'UPDATE_STREAM_INDEX':
            return {
                ...state,
                streamIndex: action.payload
            };
        case 'UPDATE_MESSAGES':
            return {
                ...state,
                messages: {
                    ...state.messages,
                    ...action.payload
                }
            };
        case 'UPDATE_IS_PIN_LOGGED_IN':
            return {
                ...state,
                isPinLoggedIn: action.payload
            };
        case 'UPDATE_GUEST_LIST':
            return {
                ...state,
                guestList: action.payload
            };
        case 'UPDATE_GUEST_LIST_SIZE':
            return {
                ...state,
                guestListSize: action.payload
            };
        case 'UPDATE_DISPLAY_SIZE':
            return {
                ...state,
                displaySize: action.payload
            };
        default:
            throw new Error();
    }
};

/**
 * Provider for EventsContext state
 */
const ViewlogyProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setUrlParams = params => dispatch({ type: 'UPDATE_URL_PARAMS', payload: params });
    const setIsLoading = isLoading => dispatch({ type: 'UPDATE_IS_LOADING', payload: isLoading });
    const setIsLoggedIn = isLoggedIn => dispatch({ type: 'UPDATE_IS_LOGGED_IN', payload: isLoggedIn });
    const setEvent = event => dispatch({ type: 'UPDATE_EVENT', payload: event });
    const setStreams = stream => dispatch({ type: 'UPDATE_STREAMS', payload: stream });
    const setStreamIndex = index => dispatch({ type: 'UPDATE_STREAM_INDEX', payload: index });
    const setMessages = messages => dispatch({ type: 'UPDATE_MESSAGES', payload: messages });
    const setIsPinLoggedIn = isPinLoggedIn => dispatch({ type: 'UPDATE_IS_PIN_LOGGED_IN', payload: isPinLoggedIn });
    const setGuestList = guestList => dispatch({ type: 'UPDATE_GUEST_LIST', payload: guestList });
    const setGuestListSize = size => dispatch({ type: 'UPDATE_GUEST_LIST_SIZE', payload: size });
    const setDisplaySize = size => dispatch({ type: 'UPDATE_DISPLAY_SIZE', payload: size });

    return (
        <Context.Provider
            value={{
                ...state,
                setUrlParams,
                setIsLoggedIn,
                setIsLoading,
                setEvent,
                setStreams,
                setStreamIndex,
                setMessages,
                setIsPinLoggedIn,
                setGuestList,
                setGuestListSize,
                setDisplaySize
            }}
        >
            <IntlProvider locale={state.language} messages={messages[state.language]}>
                {children}
            </IntlProvider>
        </Context.Provider>
    );
};

/**
 * Call this method to access Context state in components that use Provider
 */
const useViewlogies = () => {
    const value = useContext(Context);
    const {
        event,
        streams,
        streamIndex,
        setIsLoading,
        setIsLoggedIn,
        setEvent,
        setStreams,
        setMessages,
        setIsPinLoggedIn,
        setGuestList,
        setGuestListSize,
        setDisplaySize
    } = value;

    // fetch event data for current event (based on URL params)
    const fetchEvent = async params => {
        try {
            const { data } = await getEvent(params);
            setEvent(data);
            setIsLoading(false);
        } catch (e) {
            console.log('Invalid URL!');
            console.error(e);
            // redirect to 404 page
            window.location.href = 'https://www.viewlogies.com/404';
        }
    };

    const refreshEvent = async () => {
        try {
            const { organizationId, id, chapel, startTime, lastName } = event;
            const { data } = await getEvent({ organizationId, eventId: id, chapel, startTime, lastName });
            setEvent(data);
        } catch (e) {
            console.error(e);
        }
    };

    // log in for a video after a user provides input
    const videoLogin = input => {
        const { globalPin, firstName, id } = event;
        const stream = streams[streamIndex];
        const authParams = globalPin
            ? {
                  pin: globalPin,
                  eventId: id,
                  firstName
              }
            : {
                  pin: stream.pin,
                  eventId: stream.id,
                  firstName
              };
        const _isLoggedIn = authenticate(authParams, input);
        if (_isLoggedIn) {
            setIsPinLoggedIn(true);
            if (globalPin) {
                setIsLoggedIn(true);
            } else {
                streams[streamIndex].isLoggedIn = true;
                setStreams(streams);
            }
        } else {
            setMessages({ login: { error: true } });
        }
    };

    // fetch guest list for current event
    const fetchGuestList = async (_displaySize = value.displaySize) => {
        try {
            const { id, organizationId } = event;
            const {
                data: { guestList, guestListSize }
            } = await getGuestList(organizationId, id, 0, _displaySize);
            setGuestList(guestList);
            setGuestListSize(guestListSize); //Total guests in database, not total guests currently displayed
        } catch (e) {
            console.error(e);
        }
    };

    return { fetchEvent, refreshEvent, videoLogin, fetchGuestList, ...value };
};

export { useViewlogies, ViewlogyProvider };
