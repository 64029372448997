const isEventStarted = streams => {
    const minStartTime = streams.reduce(function(min, stream) {
        return min === null || stream.startTime < min ? stream.startTime : min;
    }, null);

    const eventStarted = new Date(minStartTime).getTime() < new Date().getTime();

    return eventStarted;
};

export default isEventStarted;
