import config from 'config/default';
const { default: ReCAPTCHA } = require('react-google-recaptcha');
const { FormattedMessage } = require('react-intl');

const ReCaptcha = ({ isSubmitDisabled, recaptchaRef, setMessage, isRecaptchaError }) => {
    return (
        <>
            <span className="guest-form-recaptcha-container" style={{ display: isSubmitDisabled ? 'none' : 'block' }}>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={config.recaptchaSiteKey}
                    onChange={() => setMessage('')}
                    className="guest-form-recaptcha"
                />
                {isRecaptchaError && (
                    <div className="error-message">
                        <FormattedMessage
                            id="features.Viewlogy.GuestSection.Form.notARobot"
                            defaultMessage="Please verify that you are not a robot."
                        />
                    </div>
                )}
            </span>
        </>
    );
};

export default ReCaptcha;
