import crypto from 'crypto';
import validator from 'validator';

/**
 * Set a cookie
 */
export const setCookie = (name, value, expirationDays = 30) => {
    const d = new Date();
    d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = `${name}=${value};${expires};path=/`;
};

/**
 * // TODO: modernize
 * Get a cookie name and value
 * @param {String} name - name of the cookie
 */
export const getCookie = name => {
    const _name = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(_name) === 0) {
            return c.substring(_name.length, c.length);
        }
    }
    return '';
};

/**
 * Verify that a string is a valid URL
 * @param {String} url
 */
export const isValidURL = url => {
    return validator.isURL(url);
};

/**
 * Perform a sha1 encryption on a string
 * @param {String} str - string to be encrypted
 * @return {string} encrypted value of the string
 */
export const sha1 = str =>
    crypto
        .createHash('sha1')
        .update(str, 'utf8')
        .digest('hex');

const MAX_32_BIT_INTEGER = 2147483647;

export const refresh = () => window.location.reload(true);

/**
 * Set functions to run at a specific time
 * @param {Date} time - a time to trigger functions (`fns`)
 * @param {Function[]} fns - functions to schedule
 */
export const scheduleAtTime = (time, fns) => {
    const offsetMillis = new Date(time).getTime() - new Date().getTime();
    if (offsetMillis > 0 && offsetMillis < MAX_32_BIT_INTEGER) {
        fns.forEach(fn => setTimeout(fn, offsetMillis));
    }
};
