import React, { useState, useEffect } from 'react';
import moment from 'moment';
// moment locales have to be imported explicitly :(
// TODO: add locale imports as needed
import 'moment/locale/ar';
import 'moment/locale/es';
import 'moment/locale/fa';
import 'moment/locale/fr';
import 'moment/locale/hi';
import 'moment/locale/pa-in';
import 'moment/locale/pt';
import 'moment/locale/ro';
import 'moment/locale/ru';
import 'moment/locale/tl-ph';
import 'moment/locale/vi';
import 'moment/locale/zh-cn';
import { getMomentLocale } from 'lang/util';
import { useViewlogies } from 'context';
import { useInterval } from 'hooks';
import './guestList.scss';
import { FormattedMessage } from 'react-intl';

const FETCH_INTERVAL = 30000; // 30 second interval for paging guest list table

/**
 * Instead of doing traditional pagination, the guest list size is simply incremented when a user clicks "Load more"
 * This solution should be fine for now, and it can be changed if we start having events with over 5000 entries or so
 * The issue is when guests are being added in real time, how do you load more previously created guests?
 * @return {JSX.Element}
 * @constructor
 */
const List = () => {
    const {
        displaySize,
        event: { color },
        guestList,
        guestListSize,
        fetchGuestList,
        setDisplaySize,
        language
    } = useViewlogies();

    const [isLoading, setIsLoading] = useState(false);

    const _fetchGuestList = async (_displaySize = displaySize) => {
        setIsLoading(true);
        await fetchGuestList(_displaySize);
        await fetchGuestList(_displaySize);
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => await _fetchGuestList())();
    }, []);

    useInterval(() => {
        (async () => await _fetchGuestList())();
    }, FETCH_INTERVAL);

    const guestListSizeMessage = !isNaN(guestListSize)
        ? guestListSize > 1
            ? `${guestListSize.toLocaleString()} signed guests`
            : guestListSize === 1
            ? `${guestListSize.toLocaleString()} signed guest`
            : '0 signed guests'
        : isLoading
        ? 'Loading...'
        : undefined; // Some error with the value of guestListSize, hide the text block completely

    if (!guestList.length) {
        return null;
    }

    const onLoadMoreClick = async () => {
        const _displaySize = guestList.length >= displaySize ? displaySize * 2 : displaySize;
        await _fetchGuestList(_displaySize);
        setDisplaySize(_displaySize);
    };

    return (
        <section className="guest-list-container">
            <hr />
            <p className="video-message guest-list-header" style={{ color }}>
                <FormattedMessage id="features.Viewlogy.GuestSection.List.title" defaultMessage="Guest List" />
            </p>
            {guestListSizeMessage ? (
                <p className="video-message guest-list-subtitle" style={{ color }}>
                    <FormattedMessage
                        id="features.Viewlogy.GuestSection.List.subtitle"
                        defaultMessage={guestListSizeMessage}
                    />
                </p>
            ) : (
                <></>
            )}
            <div className="guest-list">
                {guestList.map(({ name, createDate }, index) => (
                    <div className="guest-container" key={index}>
                        <div className="guest-name">{name}</div>
                        <div className="guest-date" style={{ color }}>
                            {moment(createDate)
                                .locale(getMomentLocale(language))
                                .format('L')}
                        </div>
                    </div>
                ))}
            </div>
            {displaySize < guestListSize ? (
                <button
                    className={`load-more-button${isLoading ? ' disabled' : ''}`}
                    onClick={() => onLoadMoreClick()}
                    disabled={isLoading}
                >
                    <FormattedMessage id="features.Viewlogy.GuestSection.List.loadMore" defaultMessage="Load more..." />
                </button>
            ) : (
                <></>
            )}
        </section>
    );
};

export default List;
